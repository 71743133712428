<template>
    <div class="code">
        <el-button type="primary" :disabled="isDisabled" @click="countdown">{{codeText}}</el-button>
    </div>
</template>

<script>
    export default {
        props: {
            mobile: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                codeText: '发送验证码',
                codeTime: 60,
                clearTime: '',
                isDisabled: false
            }
        },
        methods: {
            countdown() {
                this.$store.state.formCodeValid('mobile', async(result) => {
                    if(!result) {
                        const codeResult = await this.getCode()
                        if(codeResult.code == 1) {
                            this.isDisabled = true
                            this.codeText = `${this.codeTime}s`
                            this.clearTime = setInterval(() => {
                                if(this.codeTime <= 0) {
                                    clearInterval(this.clearTime)
                                    this.codeText = '发送验证码'
                                    this.codeTime = 60
                                    this.isDisabled = false
                                    return
                                }
                                this.codeTime--
                                this.codeText = `${this.codeTime}s`
                            }, 1000);
                        }
                        
                    }
                })
            },
            // 获取验证码
            getCode() {
                const { mobile } = this
                return new Promise((resolve, reject) => {
                    this.$postHttp(this.$api.postRegisteredCode, { mobile }).then(result => {
                        resolve(result)
                        if(result.code == 1) {
                            this.$successPrompt('发送成功')
                        }
                    }).catch( err => {
                        reject(err)
                    })
                })
                
            }
        },
        destroyed() {
            clearInterval(this.clearTime)
        }
    }
</script>

<style lang="scss" scoped>
.code {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    ::v-deep .el-button{
        width: 88px;
        height: 32px;
        padding: 0;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 2px;
        &.el-button--primary{
            background: $main_color;
            border-color: $main_color;
        }
    }
}
</style>